<template>
  <b-card title="当月前十推广员进粉数据">
    <app-echart-radar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartRadar from "@core/components/charts/echart/AppEchartRadar.vue";

export default {
  components: {
    BCard,
    AppEchartRadar,
  },
  data() {
    return {
      option: {
        series: [
          {
            name: "Point One vs Point Two",
            type: "radar",
            lineStyle: {
              width: 0,
            },
            symbolSize: 0,
            data: [
              {
                value: [
                  43, 65, 42, 34, 56, 213, 21, 544, 212,
                  321, 326, 232,
                ],
                name: "推广员排行榜",
                itemStyle: {
                  color: "#7367F0",
                },
                areaStyle: {
                  color: "#7367F0",
                  opacity: 0.8,
                },
              },
            ],
          },
        ],
        indicator: [
          { name: "asd", max: 2000 },
          { name: "Two", max: 2000 },
          { name: "Three", max: 2000 },
          { name: "Four", max: 2000 },
          { name: "Five", max: 2000 },
          { name: "Six", max: 2000 },
          { name: "Sever", max: 2000 },
          { name: "Eight", max: 2000 },
          { name: "Six2", max: 2000 },
          { name: "Sever3", max: 2000 },
          { name: "Eight4", max: 2000 },
          { name: "Eight5", max: 2000 },
        ],
      },
    };
  },
};
</script>
