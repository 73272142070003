<template>
  <b-card title="近30天活动渠道加粉占比">
    <div class="pie-text text-center">
      <h2 class="font-weight-bolder">{{ num }}</h2>
      <span class="font-weight-bold">人</span>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="series" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BCard,
    AppEchartDoughnut,
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .querychannlbymonth({ cid: this.cid, type: "1", addstatus: "1" })
      .then((res) => {
        console.log("res", JSON.stringify(res));
        this.series[0].data = res.data.data;
        console.log("this.series", JSON.stringify(this.series));
        for (var i = 0; i < res.data.data.length; i++) {
          var d = parseInt(res.data.data[i].value);
          this.num += d;
        }
      });
  },
  data() {
    return {
      num: 0,
      series: [
        {
          name: "近30天推广渠道占比",
          type: "pie",
          radius: ["40%", "60%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
          },
          labelLine: {
            show: true,
          },
          data: [],
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.card-body {
  position: relative;
  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
</style>
