<template>
  <b-card title="活动渠道进粉数">
    <app-echart-bar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BCard,
    AppEchartBar,
  },
  async created() {
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = "01";
    m = m < 10 ? "0" + m : m; //月份补 0
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.date = this.getFirstDay();
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = new Date(y, m, 0).getDate();

    for (var i = 1; i <= d; i++) {
      var f = m < 10 ? "0" + m : m; //月份补 0
      var c = i < 10 ? "0" + i : i; //月份补 0
      this.option.xAxis[0].data.push([y, m, c].join("-"));
    }
    console.log("this.option", JSON.stringify(this.option));
    await useJwt //查询数据库
      .querymonthbydate({
        cid: this.cid,
        type: "1",
        channltype: 2,
        addstatus: "1",
        edate: this.date,
      })
      .then((res) => {
        this.arr = res.data.data;
        for (var i = 0; i < this.arr.length; i++) {
          this.mb.name = this.arr[i].name;
          this.mb.data = this.arr[i].data;
          this.mb.itemStyle.color = this.color[i];
          this.option.series.push(JSON.parse(JSON.stringify(this.mb)));
        }
      });
  },
  methods: {
    getFirstDay() {
      //当前月第一天
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = "01";
      m = m < 10 ? "0" + m : m; //月份补 0
      return [y, m, d].join("-");
    },
  },
  data() {
    return {
      mb: {
        name: "",
        type: "bar",
        stack: "advertising",
        data: [],
        barMaxWidth: "20%",
        barMinWidth: "10px",
        itemStyle: {
          color: "",
        },
      },
      color: [
        "#37A2DA",
        "#32C5E9",
        "#67E0E3",
        "#9FE6B8",
        "#FFDB5C",
        "#ff9f7f",
        "#fb7293",
        "#E062AE",
        "#E690D1",
        "#e7bcf3",
        "#9d96f5",
        "#8378EA",
        "#96BFFF",
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
        "#dd6b66",
        "#759aa0",
        "#e69d87",
        "#8dc1a9",
        "#ea7e53",
        "#eedd78",
        "#73a373",
        "#73b9bc",
        "#7289ab",
        "#91ca8c",
        "#f49f42",
      ],
      option: {
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [],
      },
    };
  },
};
</script>
