<template>
  <b-card title="今日扫码加粉分析">
    <app-echart-stacked-area :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";

import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt.queryscodebytoday({ cid: this.cid, type: "1" }).then((res) => {
      this.option.series[0].data = res.data.data.scan;
      this.option.series[1].data = res.data.data.add;
    });
  },
  data() {
    return {
      cid: 0,
      option: {
        xAxisData: [
          "0点",
          "1点",
          "2点",
          "3点",
          "4点",
          "5点",
          "6点",
          "7点",
          "8点",
          "9点",
          "10点",
          "11点",
          "12点",
          "13点",
          "14点",
          "15点",
          "16点",
          "17点",
          "18点",
          "19点",
          "20点",
          "21点",
          "22点",
          "23点",
        ],
        series: [
          {
            name: "扫码数",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#84d0ff",
            },
            color: "#84d0fe",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [],
          },
          {
            name: "添加数",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#299aff",
            },
            color: "#399afd",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [],
          },
        ],
      },
    };
  },
};
</script>
