<template>
  <b-card title="本月扫码加粉分析">
    <app-echart-stacked-area :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  created() {
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = "01";
    m = m < 10 ? "0" + m : m; //月份补 0
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.date = this.getFirstDay();
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = new Date(y, m, 0).getDate();

    for (var i = 1; i <= d; i++) {
      var f = m < 10 ? "0" + m : m; //月份补 0
      var c = i < 10 ? "0" + i : i; //月份补 0
      this.option.xAxisData.push([y, m, c].join("-"));
    }
    useJwt //查询数据库
      .queryscodebymonth({
        cid: this.cid,
        type: "1",
        edate: this.date,
      })
      .then((res) => {
        this.option.series[0].data = res.data.data.scan;
        this.option.series[1].data = res.data.data.add;
        console.log("##########this.option", JSON.stringify(this.option));
      });
  },
  methods: {
    getFirstDay() {
      //当前月第一天
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = "01";
      m = m < 10 ? "0" + m : m; //月份补 0
      return [y, m, d].join("-");
    },
  },
  data() {
    return {
      date: "",
      arr: [],
      value: "",
      option: {
        xAxisData: [],
        series: [
          {
            name: "扫码数",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#84d0ff",
            },
            color: "#84d0fe",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [],
          },
          {
            name: "添加数",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#299aff",
            },
            color: "#399afd",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [],
          },
        ],
      },
    };
  },
};
</script>
